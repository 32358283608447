dmx.Component('leaflet-marker', {

  initialData: {
    latitude: null,
    longitude: null,
  },

  attributes: {
    latitude: {
      type: Number,
      default: null,
    },

    longitude: {
      type: Number,
      default: null,
    },

    tooltip: {
      type: String,
      default: null,
    },

    popup: {
      type: String,
      default: null,
    },

    group: {
      type: String,
      default: null,
    },

    draggable: {
      type: Boolean,
      default: false,
    },

    icon: { // icon font
      type: String,
      default: null,
    },

    iconUrl: {
      type: String,
      default: null,
    },

    color: {
      type: String,
      default: 'blue',
      enum: ['blue', 'pink', 'darkblue', 'lila', 'magenta', 'red', 'orange', 'brown', 'green', 'mint'],
    },
  },

  events: {
    click: Event,
    dblclick: Event,
    move: Event,
  },

  render: false,

  init () {
    this._clickHandler = this._clickHandler.bind(this);
    this._moveHandler = this._moveHandler.bind(this);

    const markerOptions = {};
    if (this.props.draggable) {
      markerOptions.draggable = true;
    }

    if (this.props.iconUrl) {
      markerOptions.icon = new L.Icon({ iconUrl: this.props.iconUrl });
    } else if (this.props.icon) {
      markerOptions.icon = new L.divIcon({
        className: '',
        iconSize: [25, 30],
        iconAnchor: [12, 30],
        tooltipAnchor: [16 , -15],
        popupAnchor: [1, -25],
        html: `<div class="leaflet-marker-icon-custom">
          <i class="${this.props.icon}">
        </div>`,
      });
      if (this.props.color) {
        markerOptions.icon.options.className = 'leaflet-marker-icon-' + this.props.color;
      }
    } else {
      markerOptions.icon = new L.Icon.Default();
      if (this.props.color) {
        markerOptions.icon.options.className = 'leaflet-marker-icon-' + this.props.color;
      }
    }

    this._marker = L.marker([this.props.latitude, this.props.longitude], markerOptions);
    this._marker.id = this.name;
    this._marker.static = true;

    this._marker.on('click', this._clickHandler);
    this._marker.on('dblclick', this._clickHandler);
    this._marker.on('move', this._moveHandler);

    if (this.props.tooltip) {
      this._marker.bindTooltip(this.props.tooltip);
      this._hasTooltip = true;
    }

    if (this.props.popup) {
      this._marker.bindPopup(this.props.popup);
      this._hasPopup = true;
    }

    this._updateData();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('latitude') || updatedProps.has('longitude')) {
      this._marker.setLatLng([this.props.latitude, this.props.longitude]);
    }

    if (updatedProps.has('tooltip')) {
      if (this._hasTooltip) {
        this._marker.unbindTooltip();
        this._hasTooltip = false;
      } else {
        this._marker.bindTooltip(this.props.tooltip);
        this._hasTooltip = true;
      }
    }

    if (updatedProps.has('popup')) {
      if (this._hasPopup) {
        this._marker.unbindPopup();
        this._hasPopup = false;
      } else {
        this._marker.bindPopup(this.props.popup);
        this._hasPopup = true;
      }
    }

    if (updatedProps.has('draggable')) {
      this._marker.dragging[this.props.draggable ? 'enable' : 'disable']();
    }

    if (updatedProps.has('iconUrl')) {
      this._marker.setIcon(this.props.iconUrl ? { iconUrl: this.props.iconUrl } : new L.Icon.Default());
    }

    if (updatedProps.has('icon')) {
      this._marker.setIcon(this.props.icon ? new L.divIcon({
        className: '',
        iconSize: [25, 30],
        iconAnchor: [12, 30],
        tooltipAnchor: [16 , -15],
        popupAnchor: [1, -25],
        html: `<div class="leaflet-marker-icon-custom">
          <i class="${this.props.icon}">
        </div>`,
      }) : new L.Icon.Default());
    }

    this._updateData();
  },

  destroy () {
    this._marker.remove();

    if (this._hasTooltip) {
      this._marker.unbindTooltip();
    }

    if (this._hasPopup) {
      this._marker.unbindPopup();
    }

    this._marker.clearAllEventListeners();
  },

  _updateData () {
    var coor = this._marker.getLatLng();
    this.set({
      latitude: coor.lat,
      longitude: coor.lng,
    });
  },

  _moveHandler (e) {
    this._updateData();
    this.dispatchEvent(e.type, null, {
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
      oldLatitude: e.oldLatLng.lat,
      oldLongitude: e.oldLatLng.lng,
    });
  },

  _clickHandler (e) {
    this.dispatchEvent(e.type, null, {
      id: e.target.id,
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
      altKey: e.originalEvent.altKey,
      ctrlKey: e.originalEvent.ctrlKey,
      metaKey: e.originalEvent.metaKey,
      shiftKey: e.originalEvent.shiftKey,
      pageX: e.originalEvent.pageX,
      pageY: e.originalEvent.pageY,
      x: e.originalEvent.x || e.originalEvent.clientX,
      y: e.originalEvent.y || e.originalEvent.clientY,
    });
  },

});
